import React from 'react'
import { Alert, Container } from 'react-bootstrap'
import Layout from "../components/layout"

export default () => {
    return (
        <Layout>
            <Container className="text-center">
                <Alert variant="success" className="mt-4">
                    <p>Votre réservation a été prise en compte !</p>
                    <p>Un mail de confirmation vous a été envoyé, si ce n'est pas le cas, veuillez nous contacter: <a href='mailto:support@lesenfantsdelarbrevert.fr'>support@lesenfantsdelarbrevert.fr</a></p>
                </Alert>
            </Container>
        </Layout>
    )
}